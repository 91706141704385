import React from 'react'
import Box from '@material-ui/core/Box'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import { selectAssignment, selectQuestion } from '../../misc/selectors'
import { useRouteMatch, useHistory } from 'react-router-dom'
import QuestionWithAnswersCard from '../../components/QuestionWithAnswersCard'
import ReportDialog from '../../components/ReportDialog'
import ChangeQuestionDialog from '../../components/ChangeQuestionDialog'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TickIcon from '@material-ui/icons/Check'
import PrintIcon from '@material-ui/icons/Print'
import useI18n from '../../misc/languages/useI18n'
import ConfirmDialog from '../../components/ConfirmDialog'
import { useSnackbar } from 'notistack'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PrintDialog from '../../components/PrintDialog'

const ConfirmAssignment = () => {
  const {
    params: { assignmentId },
  } = useRouteMatch()
  const history = useHistory()
  const firestore = useFirestore()
  const { t } = useI18n()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState()
  const [isPrintDialogVisible, setIsPrintDialogVisible] = React.useState(false)
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const assignment = useSelector((state) => selectAssignment(state, assignmentId))
  const questions = useSelector((state) =>
    assignment ? assignment.questions.map((q) => selectQuestion(state, q.id)) : []
  )
  useFirestoreConnect([
    ...(assignment
      ? assignment.questions.map((q) => ({ collection: 'questions', doc: q.id }))
      : []),
    ...(assignment ? assignment.chapters.map((q) => ({ collection: 'chapters', doc: q.id })) : []),
  ])

  const [reportingQuestion, setReportingQuestion] = React.useState('')
  const [changingQuestion, setChangingQuestion] = React.useState('')
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false)

  const onPublish = async () => {
    try {
      setLoading(true)
      await firestore.update({ collection: 'assignments', doc: assignmentId }, { published: true })
      history.push(`/app/assignments`)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <WithAppBar
      title={t('confirm assignment')}
      back
      rightButtonIcon={
        loading ? (
          <CircularProgress size={24} color="secondary" />
        ) : assignment?.students?.length === 0 ? (
          <PrintIcon />
        ) : (
          <TickIcon />
        )
      }
      onRightButtonClick={
        loading
          ? () => {}
          : () => {
              if (assignment.students.length === 0) {
                setIsPrintDialogVisible(true)
              } else {
                onPublish()
              }
            }
      }
    >
      <Box
        height={minAvailableScreenHeight}
        pb={get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px'}
        overflow="auto"
      >
        {assignment
          ? assignment.questions.map((q, i) => (
              <QuestionWithAnswersCard
                key={q.id}
                questionNo={i}
                question={questions[i]}
                assignmentId={assignmentId}
                correctAns={q.correctAns}
                ans={q.ans}
                mb={0}
                withFooter
                noStat
                onReportQuestion={setReportingQuestion}
                onChangeQuestion={setChangingQuestion}
              />
            ))
          : null}
        <Box
          m={4}
          display="flex"
          flexDirection={isMobile ? 'column-reverse' : 'row'}
          justifyContent="space-evenly"
        >
          <Button
            style={{ minWidth: 150, marginBottom: theme.spacing(2) }}
            fullWidth={isMobile}
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => setConfirmDeleteDialogOpen(true)}
          >
            {t('delete')}
          </Button>
          <Button
            style={{ minWidth: 150, marginBottom: theme.spacing(2) }}
            fullWidth={isMobile}
            size="large"
            variant="contained"
            onClick={() => history.goBack()}
          >
            {t('cancel')}
          </Button>
          <Button
            style={{ minWidth: 150, marginBottom: theme.spacing(2) }}
            fullWidth={isMobile}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              if (assignment.students.length === 0) {
                setIsPrintDialogVisible(true)
              } else {
                onPublish()
              }
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              t(assignment?.students?.length === 0 ? 'print' : 'publish')
            )}
          </Button>
        </Box>
      </Box>
      <ReportDialog
        isVisible={!!reportingQuestion}
        onClose={() => setReportingQuestion('')}
        questionId={get(reportingQuestion, 'id')}
        subject={get(reportingQuestion, 'subject')}
        chapter={get(reportingQuestion, 'chapter')}
      />
      <ConfirmDialog
        isVisible={confirmDeleteDialogOpen}
        onClose={() => setConfirmDeleteDialogOpen(false)}
        message={t('confirm delete assignment')}
        onSubmit={() => {
          firestore.update({ collection: 'assignments', doc: assignment.id }, { deleted: true })
          enqueueSnackbar(t('assignment deleted'), { key: assignment.id })
          history.goBack()
        }}
      />
      {assignment ? (
        <ChangeQuestionDialog
          isVisible={!!changingQuestion}
          onClose={() => setChangingQuestion('')}
          questionId={get(changingQuestion, 'id')}
          assignment={assignment}
        />
      ) : null}
      <PrintDialog
        isVisible={isPrintDialogVisible}
        onClose={() => setIsPrintDialogVisible(false)}
        assignment={assignment}
      />
    </WithAppBar>
  )
}

export default ConfirmAssignment
