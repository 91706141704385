import { slackUrl } from '../config'

const postSlack = async (body) => {
  try {
    const response = await fetch(slackUrl, {
      body: JSON.stringify(body),
      method: 'POST',
    })
    if (response.ok) {
      return response
    }
    throw response
  } catch (err) {
    return Promise.reject(err)
  }
}

export const sendEmail = async (firestore, email, message) => {
  try {
    await firestore.collection('mail').add({
      to: 'info@thinka.hk',
      from: email,
      replyTo: email,
      message: {
        subject: `[Feedback] From: ${email}`,
        html: message,
      },
    })
    return
  } catch (err) {
    return Promise.reject(err)
  }
}

export default postSlack
