import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from 'notistack'
import useI18n from '../../misc/languages/useI18n'
import Box from '@material-ui/core/Box'

const defaultReasons = [
  'unreasonable meaning',
  'unreasonable choices',
  'image not clear',
  'image doesnt make sense',
]

const ReportDialog = ({ isVisible, onClose, questionId, subject, chapter, withDefaultReasons }) => {
  const { t } = useI18n()
  const firestore = useFirestore()
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const uid = useSelector((state) => get(state, 'firebase.auth.uid'))
  const onSubmit = async () => {
    try {
      setLoading(true)
      const result = await firestore.add(
        { collection: 'reports' },
        {
          user: uid,
          createdAt: Date.now(),
          message,
          question: questionId,
          subject,
          chapter,
          deleted: false,
        }
      )
      setLoading(false)
      setMessage('')
      enqueueSnackbar(t('thank you for reporting'), { key: result.id })
      onClose()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Dialog open={isVisible} onClose={onClose} maxWidth="md">
      <DialogTitle>{t('report question')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('report question description')}</DialogContentText>
        {withDefaultReasons ? (
          <Box mb={2}>
            {defaultReasons.map((r) => (
              <>
                <Button key={r} onClick={() => setMessage((m) => (!m ? t(r) : `${m}\n${t(r)}`))}>
                  {t(r)}
                </Button>
                /
              </>
            ))}
          </Box>
        ) : null}
        <TextField
          autoFocus
          multiline
          rows="4"
          label={t('message')}
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button style={{ width: 120 }} onClick={onClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          style={{ width: 120 }}
          onClick={onSubmit}
          color="primary"
          disabled={!message || loading}
        >
          {loading ? <CircularProgress size={24} /> : t('report')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReportDialog
