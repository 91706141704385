import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import get from 'lodash/get'

const useMinAvailableSize = (querySelectors = [], spacing = 0) => {
  const theme = useTheme()
  const [height, setHeight] = React.useState(0)
  const [width, setWidth] = React.useState(0)
  const [prevHeight, setPrevHeight] = React.useState(0)
  const [prevWidth, setPrevWidth] = React.useState(0)
  const [int, setInt] = React.useState()

  const update = () => {
    const newHeight =
      document.body.clientHeight -
      querySelectors
        .map((c) => get(document.querySelector(c), 'offsetHeight', 0))
        .reduce((a, b) => a + b, 0) -
      theme.spacing(spacing)
    const newWidth =
      document.body.clientWidth -
      querySelectors
        .map((c) => get(document.querySelector(c), 'offsetWidth', 0))
        .reduce((a, b) => a + b, 0) -
      theme.spacing(spacing)
    setPrevHeight(newHeight)
    setPrevWidth(newWidth)
  }
  React.useEffect(() => {
    update()
    const interval = setInterval(update, 100)
    setInt(interval)
    return () => clearInterval(interval)
  }, [])

  React.useEffect(() => {
    if (prevHeight !== height || prevWidth !== width) {
      setHeight(prevHeight)
      setWidth(prevWidth)
    } else {
      clearTimeout(int)
    }
  }, [width, height, prevHeight, prevWidth])
  return { height, width }
}

export default useMinAvailableSize
