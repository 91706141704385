import React, { useState } from 'react'
import WithAppBar from '../../components/WithAppBar'
import GeneratePracticeForm from '../../components/GeneratePracticeForm'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useSelector } from 'react-redux'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { selectStudents, selectSchoolTeachers } from '../../misc/selectors'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import { useHistory } from 'react-router-dom'
import scrollParentToChild from '../../misc/scrollParentToChild'
import { useSnackbar } from 'notistack'
import useFirebaseFunctions from '../../misc/useFirebaseFunctions'
import useI18n from '../../misc/languages/useI18n'
import StudentsSelector from '../../components/StudentsSelector'
import { useFirebase } from 'react-redux-firebase'
import { firebaseConfig } from '../../config'
import ConfirmDialog from '../../components/ConfirmDialog'

const CreateAssignment = () => {
  const history = useHistory()
  const theme = useTheme()
  const { t } = useI18n()
  const { enqueueSnackbar } = useSnackbar()
  const firebase = useFirebase()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const defaultSchool = useSelector((state) =>
    state.firebase.profile.isSchool ? state.firebase.auth.uid : state.firebase.profile.defaultSchool
  )
  const students = useSelector((state) => selectStudents(state).filter((s) => s.accepted))
  const [selectedStudents, setSelectedStudents] = React.useState([])
  const [noStudentError, setNoStudentError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [school, setSchool] = React.useState(defaultSchool || '')

  const [generateAssignment] = useFirebaseFunctions(['generateAssignment'])

  const onSubmit = async (data) => {
    try {
      const params = { ...data, students: selectedStudents, school, chapters: [] }
      if (params.dueDate && selectedStudents.length === 0) {
        scrollParentToChild(
          document.getElementById('scroll-parent'),
          document.getElementById('select-students-box'),
          100
        )
        return setNoStudentError(true)
      }
      setNoStudentError(false)

      params.noOfQuestions = Number(params.noOfQuestions)
      params.timeLimit = Number(params.timeLimit)
      params.dueDate = params.dueDate ? params.dueDate.getTime() : Date.now()
      Object.keys(params).forEach((k) => {
        if (k.match(/^section-/)) {
          params.chapters = [...params.chapters, ...(params[k] || [])]
          delete params[k]
        }
      })
      if (!params.chapters.length) {
        return enqueueSnackbar(t('invalid chapters'))
      }
      setLoading(true)
      const { data: assignmentId } = await generateAssignment(params)
      firebaseConfig.measurementId && firebase.analytics().logEvent('create_assignment', params)
      history.push(`/app/confirm-assignment/${assignmentId}`)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [noStudentWarning, setNoStudentWarning] = useState()

  return (
    <WithAppBar title={t('create assignment')}>
      <Box id="scroll-parent" height={minAvailableScreenHeight} overflow="auto">
        <Grid container>
          <Grid item xs={12} sm={12} md={3}>
            <Box
              m={2}
              mr={isMobile ? 2 : 0}
              mb={isMobile ? 0 : 2}
              p={2}
              borderRadius="borderRadius"
              overflow="hidden"
              boxShadow={3}
              bgcolor="background.paper"
              id="select-students-box"
            >
              {noStudentError ? (
                <Typography variant="caption" color="error">
                  {t('invalid students')}
                </Typography>
              ) : null}
              <StudentsSelector
                students={students}
                selectedStudents={selectedStudents}
                setSelectedStudents={setSelectedStudents}
                school={school}
                setSchool={setSchool}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <GeneratePracticeForm
              onSubmit={selectStudents.length === 0 ? setNoStudentWarning : onSubmit}
              loading={loading}
              submitButtonText={t('create assignment')}
              withDueDate={selectedStudents.length > 0}
              ignorePremium={!!school}
            />
          </Grid>
        </Grid>
      </Box>
      <ConfirmDialog
        message={t('no student warning')}
        isVisible={!!noStudentWarning}
        onClose={() => setNoStudentWarning(undefined)}
        onSubmit={() => onSubmit(noStudentWarning)}
      />
    </WithAppBar>
  )
}

export default CreateAssignment
